<template>
  <v-slide-group v-model="model" mandatory show-arrows>
    <v-slide-item v-for="(item, i) in filters" v-slot="{ active }" :key="i">
      <v-btn
        :class="`mr-3 surface text-none ${
          $vuetify.theme.dark ? 'white--text' : 'black--text'
        }`"
        active-class="white--text primary-bg"
        :input-value="active"
        depressed
        @click="submit(item.value, i)"
      >
        {{ item.label }}
      </v-btn>
    </v-slide-item>
  </v-slide-group>
</template>

<script>
export default {
  data() {
    return {
      model: 0,
    };
  },

  props: {
    filters: Array,

    queryKey: String,

    routeRoot: String,
  },

  beforeMount() {
    this.handleCurrent();
  },

  methods: {
    submit(value, index) {
      if (!!this.queryKey) {
        if (value === this.$route.query[this.queryKey]) return;

        let query = { ...this.$route.query };

        if (!!value) query[this.queryKey] = value;
        else delete query[this.queryKey];

        this.$router.replace({ query: query }).catch({
          /* ignore */
        });
      } else {
        const path = this.routeRoot + value;

        if (path === this.$route.path) return;

        this.$router.push({ path: path, query: this.$route.query });
      }

      this.model = index;
      this.$emit("apply-filter");
    },

    handleCurrent() {
      if (!!this.queryKey) {
        const query = this.$route.query[this.queryKey];

        if (query) this.model = this.filters.map((e) => e.value).indexOf(query);
      } else {
        const path = this.$route.path;

        this.model = this.filters
          .map((e) => this.routeRoot + e.value)
          .indexOf(path);
      }
    },
  },
};
</script>

<style scoped>
.primary-bg {
  background: #48ab6f;
  background: linear-gradient(30deg, #48ab6f 0%, #48ab6f 100%);
}
</style>
